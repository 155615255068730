import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Collapse, TextField} from '@mui/material';
import {useState} from "react";

export default function Post({img, username, title, text}) {

    const [expanded, setExpanded] = useState(false);

    return (
        <Card>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={img}
                    alt="Post"
                />
                <CardContent style={{width: '100%', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Box
                        component="div"
                        sx={{
                            textOverflow: 'ellipsis',
                            my: 2,
                            overflow: 'hidden',
                            bgcolor: 'background.paper'
                        }}
                        fontSize={20}
                    >
                        {username}
                    </Box>
                    <Typography variant="body2" color="text.secondary" marginBottom={1} whiteSpace={"normal"}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" whiteSpace={"normal"} height={40}
                                style={{overflow: 'hidden', overflowY: 'scroll'}}>
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small">Like</Button>
                <Button size="small"
                        onClick={() => setExpanded(!expanded)}
                >Comment</Button>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <TextField />
                    <Button>SEND</Button>
                </CardContent>
            </Collapse>
        </Card>
    );
}