import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {styled} from "@mui/material";

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%'
    // overflow: 'hidden'
});

const MainStyle = styled('div')({
    flexGrow: 1,
    minHeight: '100%',
});

ReactDOM.render(
    <RootStyle>
        <MainStyle>
            <App/>
        </MainStyle>
    </RootStyle>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
