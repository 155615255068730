import {Container, Grid, styled} from "@mui/material";
import Post from "./Post";

const RootStyle = styled(Container)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        maxHeight: '70vh',
        overflowY: 'scroll'
    }
}));

export default function Posts({posts}) {
    return (
        <RootStyle>
            <Grid container spacing={4} style={{marginTop: 5, marginBottom: 5}}>
                {posts.map((x, i) => {
                    return (<Grid item xs={12} sm={6} md={3} key={i}>
                        <Post
                            img={'/static/cover_1.png'}
                            username={x.username}
                            title={x.title}
                            text={x.content}
                        />
                    </Grid>);
                })}
            </Grid>
        </RootStyle>
    );
}