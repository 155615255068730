import {Box, Button, Card, Container, Grid, Stack, styled, TextField} from "@mui/material";
import Posts from "../component/Posts";
import {useEffect, useState} from "react";
import axios from "axios";

const RootStyle = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        maxWidth: "xl",
        flexDirection: 'row',
        overflow: 'hidden'
    }
}));


export default function Main() {

    const [postTitle, setPostTitle] = useState('');

    const [postText, setPostText] = useState('');

    const [posts, setPosts] = useState([]);

    const [update, setUpdate] = useState(false);

    useEffect(() => {
        fetch("https://my-worker.joeyzhao98.workers.dev/posts").then(resp => resp.json())
            .then(setPosts)
    }, [update]);

    let handlePostTextChange = (e) => setPostText(e.target.value);

    let handlePostTitleChange = (e) => setPostTitle(e.target.value);

    let clearPost = (e) => {
        setPostText('');
        setPostTitle('')
    };

    let sendPost = (e) => {
        axios.post('https://my-worker.joeyzhao98.workers.dev/posts', {
            title: postTitle,
            username: 'joey',
            content: postText
        }).then(() => {
            setUpdate(!update);
            clearPost();
        });
    };

    return (
        <RootStyle>
            <Container>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card variant="outlined">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={4}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button variant="outlined" style={{marginTop: 10, width: '80%'}}
                                            component="label">Add Image <br/>(DEMO only, need docking with OSS to use)
                                        <input
                                            type="file"
                                            hidden
                                        /></Button>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Stack direction={'column'} width={'100%'} style={{alignItems: 'center'}}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Title"
                                            multiline
                                            rows={1}
                                            placeholder="What you are thinking?"
                                            style={{marginTop: 10, width: '80%'}}
                                            value={postTitle}
                                            onChange={handlePostTitleChange}
                                        />
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Posts"
                                            multiline
                                            rows={2}
                                            placeholder="What do you want to say?"
                                            style={{marginTop: 10, width: '80%'}}
                                            value={postText}
                                            onChange={handlePostTextChange}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button variant="outlined" style={{margin: 10, width: '80%'}}
                                            onClick={clearPost}>Cancel</Button>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button variant="outlined" style={{margin: 10, width: '80%'}}
                                            onClick={sendPost}>Post</Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Container style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}>
                    <Posts posts={posts}/>
                </Container>
            </Container>
        </RootStyle>
    );
}