import './App.css';
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import {CssBaseline} from "@mui/material";
import Router from "./routes";

function App() {
  return (
      <HelmetProvider>
          <BrowserRouter>
              <CssBaseline>
                  <Router/>
              </CssBaseline>
          </BrowserRouter>
      </HelmetProvider>
  );
}

export default App;